<div class="app-modal app-modal-simple">

  <div class="app-modal-content br">
    <div class="app-form-panel app-form-panel-simple">
      <div class="app-form-panel-content">
        <div class="d-sm-flex d-block grid-gap-15" style="align-items: center">
          <div>
            @if(!isWarning){ <i class="icon-cancel-circled-alt text-danger" style="font-size: 60px"></i>}
            @else{ <i class="icon-attention-alt text-warning" style="font-size: 60px"></i>}
          </div>
          <div class="fs16 text-dark" style="overflow-wrap: anywhere">
            <!-- @if(code){<div class="fw500">{{ 'error.errorCode' | translate }}: {{ code }}</div>} -->
            <div [innerHTML]="message"></div>
            @if(showCopy){<div class="mt40">
              {{ 'error.copyErrorCodeInstructions' | translate }}
            </div>}
            @if(showCopy){ <div class="mt15">
              <a href="javascript:void(0)" class="vam simple-btn simple-btn-md simple-btn-icon icon-bg"
                style="text-transform: capitalize" (click)="onCopyClick()"
                title="{{ 'error.copyErrorCode' | translate }}">
                <i class="icon-duplicate mr10"></i>
                {{ 'error.copyErrorCode' | translate }}
              </a>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="app-modal-footer aright">
    <dx-button (click)="onCloseClick()" type="default" text="{{ 'actions.ok' | translate }}">
    </dx-button>
  </div>
</div>
